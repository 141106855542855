import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Route, Redirect } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import {RichText} from 'prismic-reactjs';
import { withRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import ScrollBooster from 'scrollbooster';
import ProjectImage from './ProjectImage';
import Spinner from './Spinner';
import './Project.css';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: 4,
      loaded: false,
      textWidth: 0,
      title: "",
      description: "",
      images: [],
      innerHeight: window.innerHeight
    }
    this.sb = '';
    this.textMarker = React.createRef();
    this.container = React.createRef();
    this.heightChangeListener = React.createRef();
    window.__forceSmoothScrollPolyfill__ = true;
    smoothscroll.polyfill();
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ order: this.props.location.state.row });
    }

    const apiEndpoint = 'https://calen-knauf.cdn.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.getByUID('project', this.props.path).then(response => {
        if (response) {
          this.setState({
            title: RichText.asText(response.data.project_name),
            description: RichText.render(response.data.project_description),
            details: RichText.render(response.data.project_details),
            loaded: true,
            images: response.data.images
          });
          this.setTextWidth();
          this.scrollToProject();

          api.getSingle('index').then(response => {
            if (response) {
              let projects = response.data.index;
              let array = [];
              projects.map((project, index) => {
                array.push(project.project.uid);
              });
              const index = array.findIndex(uid => uid === this.props.path) + 1;
              console.log(index);
              this.setState({ order: index });
              this.props.history.replace({
                state: { row: index }
              });
            }
          });
        } else {
          this.setState({ notFound: true });
        }
      });
    });

    window.addEventListener("resize", this.setTextWidth);
    window.addEventListener("resize", this.setDimensions);
    this.heightChangeListener.current.contentWindow.addEventListener("resize", this.setTextWidth);
    this.heightChangeListener.current.contentWindow.addEventListener("resize", this.setDimensions);
    this.setTextWidth();
    this.setDimensions();

    this.sb = new ScrollBooster({
      viewport: this.container.current,
      onUpdate: (data)=> {
        let container = this.container.current;
        if (!container) { return };
        this.container.current.scrollLeft = data.position.x
      },
      shouldScroll: (data, event) => {
        const isTouch = !!(event.touches && event.touches[0]);
        return !isTouch;
      }
    });
  }

  componentWillUnmount() {
    this.sb.destroy();
    window.removeEventListener("resize", this.setTextWidth);
    window.removeEventListener("resize", this.setDimensions);
    this.heightChangeListener.current.contentWindow.removeEventListener("resize", this.setTextWidth);
    this.heightChangeListener.current.contentWindow.removeEventListener("resize", this.setDimensions);
  }

  componentDidUpdate() {
    requestAnimationFrame(()=> {
      this.sb.updateMetrics();
    });
  }

  scrollToProject = () => {
    const top = this.container.current.offsetTop;
    document.getElementById('root').scrollTo({
      top: top + 1,
      left: 0,
      behavior: 'smooth'
    });
  }

  setTextWidth = () => {
    const rect = this.textMarker.current.scrollWidth;
    this.setState({ textWidth: rect });
  }

  setDimensions = () => {
    this.setState({ innerHeight: window.innerHeight });
    if (window.innerWidth > 720) {
      this.setState({ columns: 4 });
    } else if ((window.innerWidth <= 720) && (window.innerWidth > 540)) {
      this.setState({ columns: 3 });
    } else if (window.innerWidth <= 540) {
      this.setState({ columns: 2 });
    }
  }

  roundOrder = (order) => {
    return Math.ceil((order - 0.5) / this.state.columns) * this.state.columns;
  }

  render() {
    if (this.state.order && (this.props.projectCount > 0)) {
      this.props.isLastChild(this.props.projectCount <= this.roundOrder(this.state.order));
    }

    let containerStyle = {
      order: this.roundOrder(this.state.order ? this.state.order : 1),
      height: this.state.innerHeight + 2
    }

    let textStyle = {
      width: this.state.textWidth
    }

    let details;
    if (this.state.details) {
      details =
        <div className="project-details-wrap">
          <div className="project-details">
            <h2>Details</h2>
            {this.state.details}
          </div>
        </div>
    }

    let spinner;
    if (!this.state.loaded) {
      spinner = <Spinner/>
    }

    return (
      <div
        className="project-content-wrap"
        ref={this.container}
        style={containerStyle}>
        {this.state.notFound ? <Redirect push to="/"/> : null}
        <div className="project-content">
          {spinner}
          <div className="project-text-wrap" style={textStyle}>
            <div ref={this.textMarker} className="content">
              <h2 className="title">{this.state.title}</h2>
              {this.state.description}
            </div>
          </div>
          <div className="images">
            {this.state.images.map((image, index) => {
              if (image.media.kind !== "image") { return }; // to do support video
              return (
                <ProjectImage
                  key={image.media.name}
                  image={image.media.url}
                  width={image.media.width}
                  height={image.media.height}
                />
              );
            })}
          </div>
          {details}
          <iframe ref={this.heightChangeListener} class="height-change-listener" tabindex="-1"></iframe>
        </div>
      </div>
    );
  }
}

export default withRouter(Project);
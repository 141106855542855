import React, { Component } from 'react';
import './ProjectImage.css';

class ProjectImage extends Component {
	constructor(props) {
    super(props);
    this.state = {
      innerHeight: 0,
      alpha: 0
    }
  }

	componentDidMount() {
    window.addEventListener("resize", this.setInnerHeight);
    this.setInnerHeight();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setInnerHeight);
  }

  setInnerHeight = () => {
    this.setState({ innerHeight: window.innerHeight });
  }

  showImage = () => {
    this.setState({ alpha: 1 });
  }

  render() {
    const style = {
      width: "calc(" + (this.state.innerHeight + 2) + "px * " + this.props.width / this.props.height + ")"
    }
    const imgStyle = {
      opacity: this.state.alpha
    }
    return (
        <div className="project-image" style={style}>
        	<img onLoad={this.showImage} style={imgStyle} draggable="false" src={this.props.image}/>
        </div>
    );
  }
}

export default ProjectImage;
import React, { Component } from 'react';
import Prismic from 'prismic-javascript';
import {RichText} from 'prismic-reactjs';
import ScrollBooster from 'scrollbooster';
import './About.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: []
    }
    this.sb = '';
    this.scrollView = React.createRef();
  }

  componentDidMount() {
    const apiEndpoint = 'https://calen-knauf.cdn.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.getSingle('about').then(response => {
        if (response) {
          this.setState({ sections: response.data.about });
        }
      });
    });

    this.sb = new ScrollBooster({
      viewport: this.scrollView.current,
      onUpdate: (data)=> {
        this.scrollView.current.scrollLeft = data.position.x
      },
      shouldScroll: (data, event) => {
        const isTouch = !!(event.touches && event.touches[0]);
        return !isTouch;
      }
    });
  }

  componentWillUnmount() {
    this.sb.destroy();
  }

  componentDidUpdate() {
    requestAnimationFrame(()=> {
      this.sb.updateMetrics();
    });
  }

  render() {
    return (
      <div
        ref={this.scrollView}
        className="about">
        <div className="wrap">
          {this.state.sections.map((section, index) => {
            return (
              <div
                key={index}
                className={`column ${(RichText.asText(section.title).toLowerCase().replace(/\s/g, ''))}`}>
                <h2>{RichText.asText(section.title)}</h2>
                {RichText.render(section.content)}
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default About;
import React, { Component } from 'react';
import './Spinner.css';

class Spinner extends Component {
  static defaultProps = {
    blink: true,
    string: "Loading…"
  }

  render() {
    return (
      <div
        id="spinner"
        className={this.props.blink ? 'blink' : ''}>
        {this.props.string}
      </div>
    );
  }
}

export default Spinner;
import React, { Component } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import './GridItem.css';

class GridItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      row: 2,
      loaded: false
    }
    this.image = React.createRef();
  }

  componentDidMount() {
    this.getRow();
    window.addEventListener("resize", this.setColumns);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setColumns);
  }

  getRow = () => {
    let style = window.getComputedStyle(this.image.current);
    this.setState({
      row: style.order
    });
  }

  showImage = () => {
    this.setState({ loaded: true });
  }

  render() {
    const style = {
      order: this.props.order ? this.props.order : "inherit"
    }
    const imageStyle = {
      opacity: this.state.loaded ? "1" : "0"
    }
    return (
      <div
        className="grid-item"
        style={style}
        ref={this.image}>
        {this.props.image ?
          <NavLink
            activeClassName="active"
            to={{
              pathname: `/projects/${this.props.permalink}`,
              state: {
                row: this.state.row
              }
            }}>
            <img
              style={imageStyle}
              onLoad={this.showImage}
              draggable="false"
              src={this.props.image}
              alt={this.props.title}/>
            <div className="number"><span>{this.props.order}</span></div>
          </NavLink>
          :
          <div className="title">{this.props.title}</div>
        }
      </div>
    );
  }
}

export default GridItem;
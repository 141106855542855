import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import GridItem from './GridItem';
import Project from './Project';
import About from './About';
import Spinner from './Spinner';
import './Fonts.css';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastChild: false
    }
    this.app = React.createRef();
  }

  componentDidMount() {
    const apiEndpoint = 'https://calen-knauf.cdn.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api.query(
        Prismic.Predicates.at('document.type', 'index'),
        { pageSize: 100 }
      ).then(response => {
        if (response) {
          let projects = response.results[0].data.index;
          let array = [];
          projects.map((project, index) => {
            array.push(project.project.id);
          });
          api.getByIDs(array, { pageSize: 100 }).then(response => {
            if (response) {
              this.setState({ projects: response.results });
            } else {
              this.setState({ notFound: !response });
            }
          });
        } else {
          this.setState({ notFound: !response });
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.state && this.props.location.state) {
      if(
        this.roundOrder(prevProps.location.state.row) <
        this.roundOrder(this.props.location.state.row)
      ) {
        document.getElementById("root").scrollBy(0, -((window.innerHeight + 2) + (window.innerWidth * this.getColumns()[1])));
      }
    }
  }

  getColumns = () => {
    if (window.innerWidth > 720) {
      return [4, 0.1]
    } else if ((window.innerWidth <= 720) && (window.innerWidth > 540)) {
      return [3, 0.15]
    } else if (window.innerWidth <= 540) {
      return [2, 0.2]
    }
  }

  isLastChild = (value) => {
    if (value === true) {
      this.app.current.classList.add("no-margin");
    } else {
      this.app.current.classList.remove("no-margin");
    }
  }

  roundOrder = (order) => {
    return Math.ceil((order - 0.5) / this.getColumns()[0]) * this.getColumns()[0];
  }

  render() {
    if (this.state.projects) {
      return (
        <div
          ref={this.app}
          className="App">
          <div id="grid">
            {this.state.projects.map((project, index) => {
              return (
                <GridItem
                  permalink={project.uid}
                  image={project.data.thumbnail.url}
                  title={project.data.project_name.text}
                  key={project.uid}
                  order={index + 1}
                />
              )
            })}
            <Route
              exact
              path="/projects/:path"
              render={({ match }) =>
                <Project
                  key={match.params.path}
                  projectCount={this.state.projects.length}
                  isLastChild={this.isLastChild}
                  path={match.params.path}
                />
              }
            />
          </div>
          <About/>
        </div>
      );
    } else if (this.state.notFound) {
      return <Spinner blink={false} string="Error"/>;
    }
    return <Spinner/>;
  }
}

export default withRouter(App);
